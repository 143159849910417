import {} from "@/api";

const defaultState = {
  designSprintDays: [],
};

const mutations = {
  setDesignSprintDays(state, payload) {
    state.designSprintDays = payload;
  },
};

// a simple action
const actions = {
  setDesignSprintDays({ commit }, payload) {
    commit("setDesignSprintDays", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
