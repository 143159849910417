const defaultState = {
  designSprintDays: [],
  clientCreditCards: [],
};

const mutations = {
  setDesignSprint(state, payload) {
    state.designSprintDays = payload;
  },
  setClientCreditCards(state, payload) {
    state.clientCreditCards = payload;
  },
};

// a simple action
const actions = {
  setDesignSprintDays({ commit }, payload) {
    commit("setDesignSprint", payload);
  },
  setClientCreditCards({ commit }, payload) {
    commit("setClientCreditCards", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
