import {} from "@/api";

const defaultState = {
  successMsg: null,
  errorMsg: null,
};

const mutations = {
  setSuccessMsg(state, payload) {
    state.successMsg = payload;
  },
  clearSuccessMsg(state, payload) {
    state.successMsg = payload;
  },
  setErrorMsg(state, payload) {
    state.errorMsg = payload;
  },
  clearErrorMsg(state, payload) {
    state.errorMsg = payload;
  },
};

// a simple action
const actions = {
  setSuccessMsg({ commit }, payload) {
    commit("setSuccessMsg", payload);
  },
  clearSuccessMsg({ commit }) {
    commit("setSuccessMsg", null);
  },
  setErrorMsg({ commit }, payload) {
    commit("setErrorMsg", payload);
  },
  clearErrorMsg({ commit }) {
    commit("setErrorMsg", null);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
