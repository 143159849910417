import {} from "@/api";
import Axios from "axios";
let baseURL;
if (process.env.NODE_ENV === "development") {
  baseURL = "https://grupa-api.herokuapp.com/api";
} else {
  baseURL = "https://apiservices.grupa.io/beta/api";
}
// const baseURL = 'https://grupa-api.herokuapp.com/api';

const defaultState = {
  registerClientForm: {
    name: "",
    email: "",
    password: "",
    category: "",
    app_type: "",
    new_app: "",
  },
  clientProjectDetail: {
    project_name: "",
    overview: "",
    similar_sites: [],
    stack: [],
    stack_preference: 0,
    select_pref: 0,
    kick_off: "",
    nda: null,
  },
  chosenTeam: {},
  projectId: null,
  project: null,
  selectionStage: null,
  selectedWorkMode: null,
  saveTeamName: "",
  selectedPlan: null,
  projectOverview: null,
};

const mutations = {
  updateProjectDetail(state, payload) {
    const {
      project_name,
      overview,
      similar_sites,
      stack,
      stack_preference,
      select_pref,
      kick_off,
      nda,
    } = payload;
    state.clientProjectDetail.project_name = project_name;
    state.clientProjectDetail.overview = overview;
    state.clientProjectDetail.similar_sites = similar_sites;
    state.clientProjectDetail.stack = stack;
    state.clientProjectDetail.stack_preference = stack_preference;
    state.clientProjectDetail.select_pref = select_pref;
    state.clientProjectDetail.kick_off = kick_off;
    state.clientProjectDetail.nda = nda;
  },
  updateRegistrationFormData(state, payload) {
    const { name, email, password } = payload;
    state.registerClientForm.name = name;
    state.registerClientForm.email = email;
    state.registerClientForm.password = password;
  },
  updateProjectType(state, payload) {
    state.registerClientForm.category = payload;
  },
  updateSelectionStage(state, payload) {
    state.selectionStage = payload;
  },
  updateWorkModePage(state, payload) {
    state.selectedWorkMode = payload;
  },
  updateSelectedPlan(state, payload) {
    state.selectedPlan = payload;
  },
  updateProject(state, payload) {
    state.project = { ...payload };
  },
  updateProjectStage(state, payload) {
    state.registerClientForm.new_app = payload;
  },
  updateProjectCategory(state, payload) {
    state.registerClientForm.app_type = payload;
  },
  setProjectOverview(state, payload) {
    state.projectOverview = payload;
  },
  updatechosenTeam(state, payload) {
    state.chosenTeam = { ...payload };
  },
  updateprojectId(state, payload) {
    state.projectId = payload;
  },
  updatechosenName(state, payload) {
    state.saveTeamName = payload;
  },
  resetProject(state, payload) {
    state.project = payload;
  },
  resetClientProject(state, payload) {
    state.clientProjectDetail = payload;
  },
};

const actions = {
  updateProjectDetail({ commit }, payload) {
    commit("updateProjectDetail", payload);
  },
  updateRegistrationFormData({ commit }, payload) {
    commit("updateRegistrationFormData", payload);
  },
  updateProjectType({ commit }, payload) {
    commit("updateProjectType", payload);
  },
  updateProjectStage({ commit }, payload) {
    commit("updateProjectStage", payload);
  },
  updateSelectionStage({ commit }, payload) {
    commit("updateSelectionStage", payload);
  },
  updateWorkModePage({ commit }, payload) {
    commit("updateWorkModePage", payload);
  },
  updateSelectedPlan({ commit }, payload) {
    commit("updateSelectedPlan", payload);
  },
  updateProjectCategory({ commit }, payload) {
    commit("updateProjectCategory", payload);
  },
  setProjectOverview({ commit }, payload) {
    commit("setProjectOverview", payload);
  },
  updatechosenTeam({ commit }, payload) {
    Axios.get(baseURL + "/onboarding/client/work_with_team/" + payload)
      .then((resp) => {
        commit("updatechosenTeam", resp.data.data);
      })
      .catch((err) => {
        // this.$developConsole(err, 'Error')
      });
  },
  updatechosenName({ commit }, payload) {
    commit("updatechosenName", payload);
  },
  updateprojectId({ commit }, payload) {
    commit("updateprojectId", payload);
  },
  updateProject({ commit }, payload) {
    commit("updateProject", payload);
  },
  resetProjectFn({ commit }) {
    const payload = {};
    commit("resetProject", payload);
  },
  resetClientProject({ commit }) {
    const payload = {};
    commit("resetClientProject", payload);
  },
};

const getters = {
  // messages: state => state.messages,
  selectedTeam: (defaultState) => {
    return defaultState.chosenTeam;
  },
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
