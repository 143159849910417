const defaultState = {
  clientProject: {},
  clientProjectDoc: [],
  allProjects: [],
  projectActivity: [],
  projectTeam: [],
  projectPrice: {},
  startupStage: 0,
};

const mutations = {
  updateProject(state, payload) {
    state.clientProject = payload;
  },
  updateClientProjectDoc(state, payload) {
    state.clientProjectDoc = payload;
    if (payload.project) {
      state.clientProject = payload.project;
      state.startupStage = payload.project.startup
        ? payload.project.startup.stage_category_id
        : 0;
    }
  },
  setAllProjects(state, payload) {
    state.allProjects = payload;
  },
  updateProjectActivity(state, payload) {
    state.projectActivity = payload;
  },
  setProjectTeam(state, payload) {
    state.projectTeam = payload;
  },
  setProjectPrice(state, payload) {
    state.projectPrice = payload;
  },
};

// a simple action
const actions = {
  updateProject({ commit }, payload) {
    commit("updateProject", payload);
  },
  setProjectDetails({ commit }, payload) {
    commit("setProjectTeam", payload.team_members);
    commit("updateClientProjectDoc", payload);
  },
  updateClientProjectDoc({ commit }, payload) {
    commit("updateClientProjectDoc", payload);
  },
  setAllProjects({ commit }, payload) {
    commit("setAllProjects", payload);
  },
  updateProjectActivity({ commit }, payload) {
    commit("updateProjectActivity", payload);
  },
  setProjectPrice({ commit }, payload) {
    commit("setProjectPrice", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
