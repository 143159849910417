import { getTeamsforWorkMode, getTeamsforProject } from "@/api";

const defaultState = {
  priceList: [],
  availablePrice: [],
  availableTeams: [],
  availableTeamsLoading: 0,
  availableTeamsMessage: "",
  selectTeamProject: [],
  selectedPlan: null,
  saveTeamName: "",
  count: 0,

  engineerRole: { engineers: [], role: [] },
};

const mutations = {
  setTeamFroProject(state, payload) {
    state.availableTeams = payload.data;
    if (payload.status) {
      state.availableTeamsLoading = payload.status;
    }
    state.availableTeamsMessage = payload.message;
  },
  resetTeamForProject(state, payload) {
    state.availableTeams = payload.data;
    state.availableTeamsLoading = payload.status;
    state.availableTeamsMessage = payload.message;
  },
  updateSelectedPlan(state, payload) {
    state.selectedPlan = payload;
  },
  updatechosenName(state, payload) {
    state.saveTeamName = payload;
  },
  setGrupaPrices(state, payload) {
    state.priceList = payload;
  },
  counter(state, payload) {
    // alert(payload);
    // console.log(state);
    // console.log(payload);
    state.count = payload.token;
  },

  pushEngineer(state, payload) {
    state.engineerRole.engineers.push[payload.index].push(payload.data);
  },

  updateProjectRoles(state, payload) {
    if (state.engineerRole.hasOwnProperty("role")) {
    } else {
      try {
        if (payload.hasOwnProperty("data")) {
          state.engineerRole.role = payload.data;
        } else if (payload.hasOwnProperty("engineers")) {
          state.engineerRole.engineers = payload.engineers;
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (state.engineerRole.hasOwnProperty("engineers")) {
    } else {
      try {
        if (payload.hasOwnProperty("data")) {
          state.engineerRole.role = payload.data;
        } else if (payload.hasOwnProperty("engineers")) {
          state.engineerRole.engineers = payload.engineers;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
};

// a simple action
const actions = {
  async selectTeamForProject({ commit }, payload) {
    commit("setTeamFroProject", payload);
  },
  resetTeamForProject({ commit }) {
    const payload = {
      data: [],
      message: "",
      status: 0,
    };
    commit("resetTeamForProject", payload);
  },
  updateSelectedPlan({ commit }, payload) {
    commit("updateSelectedPlan", payload);
  },
  updatechosenName({ commit }, payload) {
    commit("updatechosenName", payload);
  },
  setGrupaPrices({ commit }, payload) {
    commit("setGrupaPrices", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
