import { devTeamLeadRegistration } from "@/api";

const defaultState = {
  devRegistrationFormData: { name: "", email: "" },
  createAccountRequestStatus: null,
  userEmail: "",
  registrationToken: null,
  userLinkedinData: null,
  userLinkedinURL: null,
};

const mutations = {
  async updateRegistrationFormData(state, payload) {
    // state.createAccountRequestMessage = payload.message;
    state.createAccountRequestStatus = payload.status;
    state.devRegistrationFormData.name = payload.form.name;
    state.devRegistrationFormData.email = payload.form.email;
  },
  setUserEmail(state, payload) {
    state.userEmail = payload;
  },
  setRegistrationToken(state, payload) {
    state.registrationToken = payload;
  },
  setLinkedinProfile(state, payload) {
    state.userLinkedinData = payload.data;
    state.userLinkedinURL = payload.profileUrl;
  },
  setLinkedinProfileUrl(state, payload) {
    state.userLinkedinURL = payload;
  },
};

const actions = {
  async updateRegistrationFormData({ commit }, payload) {
    const responseData = await devTeamLeadRegistration(payload)
      .then((response) => {
        if (response.data.status === 1) {
          return {
            status: response.data.status,
            message: "Account created successfully.",
            form: JSON.parse(JSON.stringify(payload)),
            spinner: false,
          };
        }
        return {
          status: response.data.status,
          message: "An error occured.",
          form: JSON.parse(JSON.stringify(payload)),
          spinner: false,
        };
      })
      .catch((error) => {
        const errorsMessages = Object.values(error.response.data.errors).map(
          (error) => {
            return error[0];
          }
        );
        return {
          status: 0,
          message: errorsMessages,
          form: JSON.parse(JSON.stringify(payload)),
          spinner: false,
        };
      });
    commit("updateRegistrationFormData", responseData);
    return responseData;
  },
  updateUserEmail({ commit }, payload) {
    commit("setUserEmail", payload);
  },
  setRegistrationToken({ commit }, payload) {
    commit("setRegistrationToken", payload);
  },
  setLinkedinProfile({ commit }, payload) {
    commit("setLinkedinProfile", payload);
  },
  setLinkedinProfileUrl({ commit }, payload) {
    commit("setLinkedinProfileUrl", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
