import { makePayment } from "@/api";
import Axios from "axios";
// const baseURL = 'https://grupa-api.herokuapp.com/api';
let baseURL;
if (process.env.NODE_ENV === "development") {
  baseURL = "https://grupa-api.herokuapp.com/api";
} else {
  baseURL = "https://apiservices.grupa.io/beta/api";
}

const defaultState = {
  paymentReqMessage: "",
  paymentReqStatus: 0,
  paymentReqData: {},
};

const mutations = {
  updatePaymentReq(state, payload) {
    state.paymentReqMessage = payload.message;
    state.paymentReqData = payload.data;
    state.paymentReqStatus = payload.status;
  },
};

const actions = {
  async makePaymentFn({ commit }, requestParams) {
    const payload = await makePayment(requestParams)
      .then((resp) => {
        return {
          message: resp.data.msg,
          data: resp.data.data,
          status: 1,
          type: "success",
        };
      })
      .catch((err) => {
        return {
          message: "An error occured. Could not charge card",
          data: {},
          status: 2,
          type: "error",
        };
      });
    commit("updatePaymentReq", payload);
  },
  resetPaymentReqFn({ commit }) {
    const payload = {
      message: "",
      data: {},
      status: 0,
      type: "reset",
    };
    commit("updatePaymentReq", payload);
  },
};

const getters = {
  selectedTeam: (defaultState) => {
    return defaultState.chosenTeam;
  },
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
