const defaultState = {
  loading: true,
};

const mutations = {
  setAuthenticatedLoader(state, payload) {
    state.loading = payload;
  },
};

// a simple action
const actions = {
  setAuthenticatedLoader({ commit }, payload) {
    commit("setAuthenticatedLoader", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
