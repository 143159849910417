import onboardingRoutes from "./onboarding";
import dashboardRoutes from "./dashboard";
import store from "@/store";

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
let routes = onboardingRoutes.concat(dashboardRoutes);
const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: routes,
});

function authGuard() {
  var user = store.state.userManagement.currentUser;
  if (user && user.user_type) {
    if (user.user_type == 1 || user.user_type == 6) {
      return "dev";
    } else if (user.user_type == 2 || user.user_type == 5) {
      return "client";
    }
  } else {
    return null;
  }
}
router.beforeEach((to, from, next) => {
  let guest = authGuard();
  if (to.meta.hasOwnProperty("auth") && to.meta.auth !== guest) {
    next("/");
    store.dispatch("userManagement/logOutUser");
  } else {
    next();
  }
});
export default router;
