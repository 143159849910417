const defaultState = {
  currentUser: null,
  userLocation: null,
  isAuthenticated: false,
  userType: null,
  forgetData: {
    forgottenMsg: "",
    status: false,
  },
  linkedinLogin: "teamLead",
  referralCode: null,
  googleSignInData: null,
  customerType: null,
  ipAddress: null,
};

const mutations = {
  setSelectedProject(state, payload) {
    // state.selectedProject = payload;
  },
  setLoggedInUser(state, payload) {
    state.currentUser = payload;
  },
  resetState(state) {
    state.currentUser = null;
  },
  setUserLocation(state, payload) {
    state.userLocation = payload;
  },
  setAutheticatedUser(state, payload) {
    state.currentUser = payload.user;
    state.isAuthenticated = payload.isAuthenticated;
    state.userType = payload.userType;
  },
  logOutUser(state) {
    state.currentUser = null;
    state.isAuthenticated = false;
    state.userType = 0;
  },
  updateCurrentUser(state, payload) {
    state.currentUser = payload;
  },
  setForgottenMsg(state, payload) {
    const { forgottenMsg, status } = payload;
    state.forgetData.forgottenMsg = forgottenMsg;
    state.forgetData.status = status;
  },
  setLinkedinLogin(state, payload) {
    state.linkedinLogin = payload;
  },
  setUserReferralCode(state, payload) {
    state.referralCode = payload;
  },
  setGoogleSignInData(state, payload) {
    state.googleSignInData = payload;
  },
  setCustomerType(state, payload) {
    state.customerType = payload;
  },
  setUserIpAddress(state, payload) {
    state.ipAddress = payload;
  },
};

// a simple action
const actions = {
  setProject({ commit }, project) {
    commit("setSelectedProject", project);
  },
  setLoggedInUser({ commit }) {
    commit("setLoggedInUser", JSON.parse(localStorage.getItem("grupa_user")));
  },
  updateUserDetails({ commit }, payload) {
    commit("setLoggedInUser", payload);
  },
  resetUserLogin({ commit }) {
    commit("resetState");
  },
  setUserLocation({ commit }, payload) {
    commit("setUserLocation", payload);
  },
  setAutheticatedUser({ commit }, payload) {
    commit("setAutheticatedUser", payload);
  },
  logOutUser({ commit }) {
    commit("logOutUser");
    localStorage.removeItem("grupa_user");
  },
  updateCurrentUser({ commit }, payload) {
    commit("updateCurrentUser", payload);
  },
  setForgottenMsg({ commit }, payload) {
    commit("setForgottenMsg", payload);
  },
  setLinkedinLogin({ commit }, payload) {
    commit("setLinkedinLogin", payload);
  },
  setUserReferralCode({ commit }, payload) {
    commit("setUserReferralCode", payload);
  },
  setGoogleSignInData({ commit }, payload) {
    commit("setGoogleSignInData", payload);
  },
  setCustomerType({ commit }, payload) {
    commit("setCustomerType", payload);
  },
  setUserIpAddress({ commit }, payload) {
    commit("setUserIpAddress", payload);
  },
};

const getters = {
  loginUser: (state) => state.currentUser,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
