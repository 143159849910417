import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import Mixpanel from "mixpanel";
import VueRouter from "vue-router";
import Axios from "axios";
import "./assets/global.scss";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import moment from "moment";
import router from "./routes/index";
import VueTelInput from "vue-tel-input";
import store from "./store";
import { initializeApp } from "firebase/app";

import "@/plugins/echarts";

Vue.prototype.$developConsole = function (data, msg) {
  if (process.env.NODE_ENV !== "production") {
   // return console.log(data, msg);
  }
};

Vue.prototype.$Mixpanel = Mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);
Vue.config.productionTip = false;
Vue.prototype.$baseUrl = process.env.VUE_APP_BACKEND_API;
Vue.prototype.$Website = process.env.VUE_APP_BASEURL;

Vue.prototype.$imageUrl = process.env.VUE_APP_AMAZON_URL + "/";
Vue.prototype.$portfolioUrl = process.env.VUE_APP_AMAZON_URL + "/";
Vue.prototype.$docUrl = process.env.VUE_APP_AMAZON_URL + "/docs/";
Vue.prototype.$videoUrl = process.env.VUE_APP_AMAZON_URL + "/videos/";

Vue.prototype.$linkedinRedirectURL = [
  "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=",
  process.env.VUE_APP_LINKEDIN_CLIENT_ID,
  "&redirect_uri=",
  process.env.VUE_APP_BASEURL,
  "/linkedin-verify&state=987654321&scope=r_basicprofile%20r_emailaddress",
].join("");

Vue.prototype.$referralUrl = `${process.env.VUE_APP_BASEURL}/signup`;

if (process.env.NODE_ENV === "development") {
  Vue.config.devtools = true;
}

// Firebase configuration
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
initializeApp(firebaseConfig);
// let db = firebase.firestore();
// window.db = db;

// Moment global variable
Object.defineProperty(Vue.prototype, "$moment", { value: moment });

// Vue custom directives
Vue.directive("custom-click-outside", {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {
      //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) {
        // and if it did, call method provided in attribute value
        var func = vnode.context[binding.expression];
        if (func) {
          func(event);
        }
      }
    };
    document.body.addEventListener("click", element.clickOutsideEvent);
  },
  unbind: function (element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});

Vue.use(VueTelInput);
Vue.use(Vuetify);
Vue.use(VueRouter, Axios);
Vue.use(Vuetify, {
  iconfont: "mdi", // 'md' || 'mdi' || 'fa' || 'fa4'
});

//Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin);

Axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
window.app = new Vue({
  el: "#app",
  store,
  render: (h) => h(App),
  router,
}).$mount("#app");
