const defaultState = {
  currentTask: { teams: [] },
};

const mutations = {
  updateCurrentTask(state, payload) {
    state.currentTask = payload;
  },
};

// a simple action
const actions = {
  updateCurrentTask({ commit }, payload) {
    commit("updateCurrentTask", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
