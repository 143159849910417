const defaultState = {
  paymentModalVisible: false,
  isViewProfile: false,
  invitationModalVisible: false,
};

const mutations = {
  updatePaymentModal(state, payload) {
    state.paymentModalVisible = payload;
    // if (payload == false) {
    //   window.location.reload();
    // }
  },
  updateInvitationModal(state, payload) {
    state.invitationModalVisible = payload;
  },
  toogleViewProfile(state, payload) {
    state.isViewProfile = payload;
  },
};

// a simple action
const actions = {
  updatePaymentModalFn({ commit }, payload) {
    commit("updatePaymentModal", payload);
  },
  updateInvitationModal({ commit }, payload) {
    commit("updateInvitationModal", payload);
  },
  toogleViewProfile({ commit }, payload) {
    commit("toogleViewProfile", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};
const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
