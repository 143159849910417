import { validateClient, validateDev } from "@/components/authGuide";

const onboardingRoutes = [
  {
    path: "/",
    name: "user_type",
    component: () => import("@/views/userType"),
    props: true,
  },
  {
    path: "/signup",
    name: "sign_up",
    component: () => import("@/views/auth/signup"),
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/login"),
    props: true,
  },
  {
    path: "/form",
    name: "form_login",
    component: () => import("@/views/loginForm"),
    props: true,
  },

  {
    path: "/linkedin-profile",
    name: "linkedin_profile",
    component: () => import("@/views/onboarding/dev/linkedin"),
    meta: { auth: validateDev() },
    props: true,
  },
  {
    path: "/team-interests",
    name: "team_interests",
    component: () => import("@/views/onboarding/dev/teamInterests"),
    meta: { auth: validateDev() },
    props: true,
  },
  {
    path: "/team-option",
    name: "team_option",
    component: () => import("@/views/onboarding/dev/teamOption"),
    meta: { auth: validateDev() },
    props: true,
  },

  {
    path: "/join-team/:reg_token",
    name: "join_team",
    component: () => import("@/views/onboarding/dev/joinTeam"),
    props: true,
  },
  {
    path: "/linkedin-verify",
    name: "linkedin_verify",
    component: () =>
      import("@/views/onboarding/dev/verification/verifyLinkedinUser"),
    props: true,
  },
  ,
  {
    path: "/google/verification",
    name: "google_verification",
    component: () =>
      import("@/views/onboarding/dev/verification/verifyGoogleUser"),
    props: true,
  },

  {
    path: "/google/new/user/verification",
    name: "google_new_user_verification",
    component: () =>
      import("@/views/onboarding/dev/verification/verifyGoogleNewUser"),
    props: true,
  },

  {
    path: "/linkedin/new/user/verification",
    name: "linkedin_new_user_verification",
    component: () =>
      import("@/views/onboarding/dev/verification/verifyLinkedInNewUser"),
    props: true,
  },

  ,
  {
    path: "/nylas/verification",
    name: "nylas_verification",
    component: () =>
      import("@/views/onboarding/dev/verification/verifyNylasAuth"),
    props: true,
  },

  // Client Routes
  {
    path: "/start-project",
    name: "start_project",
    component: () => import("@/views/onboarding/client/register"),
    props: true,
    meta: { auth: validateClient() },
  },

  // Client Routes
  {
    path: "/edit-project/:project_id",
    name: "edit_project",
    // component: () => import("@/views/onboarding/client/edit_project"),
    props: true,
    meta: { auth: validateClient() },
  },
  {
    path: "/client-option",
    name: "client_option",
    component: () => import("@/views/onboarding/client/clientOption"),
    meta: { auth: validateClient() },
    props: true,
  },
  {
    path: "/add-collaborators/:id",
    name: "add_collaborators",
    component: () =>
      import("@/views/onboarding/client/collaborator/addCollaborators"),
    props: true,
    meta: { auth: validateClient() },
  },
  {
    path: "/work-mode/:id",
    name: "work_mode",
    component: () => import("@/views/onboarding/client/workMode"),
    props: true,
    meta: { auth: validateClient() },
  },
  {
    path: "/select-team/:id",
    name: "select_team",
    component: () => import("@/views/onboarding/client/selectTeam"),
    props: true,
    meta: { auth: validateClient() },
  },
  {
    path: "/view-team/:teamId/:id",
    name: "view_team",
    component: () => import("@/views/onboarding/client/viewTeam"),
    props: true,
    meta: { auth: validateClient() },
  },

  {
    path: "/vc/:slug",
    name: "vc_slug",
    component: () => import("@/views/onboarding/vc/getVC"),
    props: true,
   // meta: { auth: validateClient() },
  },
  {
    path: "/team-evaluation/:id",
    name: "team_evaluation",
    component: () => import("@/views/onboarding/client/teamEvaluation"),
    props: true,
    meta: { auth: validateClient() },
  },
  {
    path: "/pay-design-sprint/:id",
    name: "pay_design_sprint",
    component: () => import("@/views/onboarding/client/designSprintFee"),
    props: true,
    meta: { auth: validateClient() },
  },
  {
    path: "/invite/collaborator/:token",
    name: "accept_collaborator",
    component: () =>
      import("@/views/onboarding/client/collaborator/accept_invite"),
  },
  {
    path: "/referral-page",
    name: "referral_page",
    component: () => import("@/views/referralPage"),
    props: true,
  },
  {
    path: "/reserve-team/:id",
    name: "reserve_team",
    component: () => import("@/views/onboarding/client/reserveTeam"),
    props: true,
  },
  {
    path: "/preview-potential-team/:id",
    name: "preview_potential_team",
    component: () => import("@/views/onboarding/client/previewPotentialTeam"),
    props: true,
  },
  {
    path: "/schedule-call/:id",
    name: "schedule_call",
    component: () => import("@/views/onboarding/client/scheduleCall"),
    props: true,
  },
  {
    path: "/waiting-list/:id",
    name: "waiting_list",
    component: () => import("@/views/onboarding/client/waitingList"),
    props: true,
  },
  {
    path: "/join-conversation",
    name: "join_conversation",
    component: () => import("@/views/onboarding/client/joinConversation"),
    props: true,
  },
];

export default onboardingRoutes;
