import store from "@/store";
import Axios from "axios";
let { currentUser: user, isAuthenticated } = store.state.userManagement;
const validateUser = () => {
  let authUser;
  if (user) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
    Axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    if (user.user_type == 1 || user.user_type == 6) {
      return (authUser = "dev");
    } else if (user.user_type == 2) {
      return (authUser = "client");
    }
  } else {
    return (authUser = null);
  }
};

export const validateDev = () => {
  return "dev";
};

export const validateClient = () => {
  return "client";
};
export default validateUser();
