const defaultState = {
  teamMiniProject: [],
  onboardingPortfolio: [],
};

const mutations = {
  setMiniProject(state, payload) {
    state.teamMiniProject = payload.miniProject;
    if (payload.portfolios) {
      state.onboardingPortfolio = payload.portfolios;
    }
  },
  setTeamPort(state, payload) {
    state.onboardingPortfolio = payload.portfolios;
  },
  updatePortfolio(state, payload) {
    state.onboardingPortfolio.push(payload);
  },
  removeTeamPortfolio(state, payload) {
    state.onboardingPortfolio = state.onboardingPortfolio.filter(
      (item) => item.id !== payload
    );
  },
};

const actions = {
  setMiniProject({ commit }, payload) {
    commit("setMiniProject", payload);
  },
  setTeamPort({ commit }, payload) {
    commit("setTeamPort", payload);
  },
  updatePortfolio({ commit }, payload) {
    commit("updatePortfolio", payload);
  },
  removeTeamPortfolio({ commit }, payload) {
    commit("removeTeamPortfolio", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};

const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
