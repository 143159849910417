import Axios from "axios";
let baseURL = process.env.VUE_APP_BACKEND_API;
// let baseURL = `https://grupa-api-pre-live.herokuapp.com/api`;

const user = JSON.parse(localStorage.getItem("grupa_user"));

const axios = Axios.create({
  baseURL,
  timeout: 100000,
});

axios.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("grupa_user"));
    config.headers.Authorization = `Bearer ${user.token}`;
    // config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] = `application/json`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setUserToken = (token) => {
  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.common["Access-Control-Allow-Origin"] = "*";
      config.headers.common["Content-Type"] = `application/json`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

export const fetchClientProjects = async () => {
  // alert(baseURL)
  try {
    const resp = await axios.get(`${baseURL}/client`, { timeout: 100000 });
    return resp;
  } catch (error) {
    return error;
  }
};
export const fetchProject = async (id) => {
  try {
    const resp = await axios.get(`${baseURL}/client/project/${id}`);
    return resp;
  } catch (error) {
    return error;
  }
};
export const userProfile = () => {
  return axios.get(`${baseURL}/user`);
};
export const fetchClientPayments = async (projectId) => {
  try {
    const resp = await axios.get(
      `${baseURL}/client/project/payments/${projectId}`
    );
    return resp;
  } catch (error) {
    return error;
  }
};

export const makePayment = async (payload) => {
  const { data, url } = payload;
  try {
    const resp = await axios.post(`${baseURL}/client/project/${url}`, data);
    return resp;
  } catch (error) {
    // this.$developConsole(error.response, 'request failed');
    return error;
  }
};
export const savePaymentCard = (payload) => {
  return axios.post(`${baseURL}/client/wallet/card/stripe`, payload);
};

export const getScheduledCall = async (payload) => {
  const url = payload.isTeam ? "dev" : "client";
  try {
    const resp = await axios.get(
      `${baseURL}/${url}/project/schedule_call/${payload.id}`
    );
    return resp;
  } catch (error) {
    return error;
  }
};

export const scheduleCall = async (data) => {
  try {
    const resp = await axios.post(`${baseURL}/client/schedule_call`, data);
    return resp;
  } catch (error) {
    return error;
  }
};
export const updateActivationLevel = async (param) => {
  try {
    const resp = await axios.post(
      `${baseURL}/dev/onboarding/activation_level`,
      param
    );
    return resp;
  } catch (error) {
    return error;
  }
};

export const changeActivationLevel = async () => {
  try {
    const resp = await axios.post(`${baseURL}/dev/onboarding/read_doc`);
    return resp;
  } catch (error) {
    return error;
  }
};

export const acceptProject = async (id, payload) => {
  try {
    const resp = await axios.post(
      `${baseURL}/dev/accept_project/${id}`,
      payload
    );
    return resp;
  } catch (error) {
    return error;
  }
};

export const confirmMeeting = async (id, kickoff) => {
  try {
    const resp = await axios.post(`${baseURL}/dev/confirm_schedule_call`, {
      meeting_id: id,
      remark: kickoff,
    });
    return resp;
  } catch (error) {
    return error;
  }
};

export const confirmMeetingOccured = async (id) => {
  try {
    const resp = await axios.post(
      `${baseURL}/client/project/confirm_first_meeting/${id}`
    );
    return resp;
  } catch (error) {
    return error;
  }
};

export const devTeamLeadRegistration = (payload) => {
  return Axios.post(`${baseURL}/onboarding/dev/sign_up`, payload);
};

export const getProject = (id) => {
  return axios.get(`${baseURL}/client/project/` + id);
};

export const projectObject = (id) => {
  return axios.get(`${baseURL}/client/project/1/` + id);
};

export const devStartSprintOne = (payload) => {
  return axios.post(`${baseURL}/client/project/change_progress`, payload);
};

export const getDev = () => {
  return axios.get(`${baseURL}/dev`);
};

export const getDevProject = (id) => {
  return axios.get(`${baseURL}/dev/project/` + id);
};

export const getIteration = (id) => {
  return axios.get(`${baseURL}/dev/project/get-iteration/` + id);
};

// dev create new project version/iteration
export const devCreateIteration = (payload) => {
  return axios.post(`${baseURL}/dev/project/iteration`, payload);
};

// dev update project version/iteration
export const devUpdateIteration = (payload) => {
  return axios.put(`${baseURL}/dev/project/iteration`, payload);
};

// dev delete project version/iteration
export const devDeleteIteration = (id) => {
  return axios.delete(`${baseURL}/dev/project/iteration/` + id);
};

// dev create new project milestone/sprint
export const devCreateFeature = (payload) => {
  return axios.post(`${baseURL}/dev/project/feature`, payload);
};

// dev get project documents
export const devgetProjectDocuments = (id) => {
  return axios.get(`${baseURL}/dev/project/media/` + id);
};

// Client Accept Roadmap
export const clientAcceptRoadmap = (payload) => {
  return axios.post(`${baseURL}/client/accept_roadmap`, payload);
};

//Client Accept Roadmap Per Iteration
export const AcceptSprintPlan = (payload) => {
  return axios.post(`${baseURL}/client/project/accept_sprint_plan`, payload);
};

// dev share roadmap
export const devShareRoadmap = (id) => {
  return axios.post(`${baseURL}/dev/project/share_roadmap/` + id);
};

//Dev Share Roadmap Per Iteration
export const ShareSprintPlan = (payload) => {
  return axios.post(`${baseURL}/dev/project/share_sprint_plan`, payload);
};

export const devMarkComplete = (payload) => {
  return axios.post(
    `${baseURL}/dev/project/task/complete/` + payload.id,
    payload
  );
};

export const devGetComments = (payload) => {
  if (payload.more && payload.more != "") {
    return axios.get(
      `${baseURL}/dev/project/feature/comment/` +
        payload.id +
        `?last_comment_id=` +
        payload.more
    );
  } else {
    return axios.get(`${baseURL}/dev/project/feature/comment/` + payload.id);
  }
};

export const devPostComment = (payload) => {
  return axios.post(`${baseURL}/dev/project/feature/comment`, payload);
};

export const clientUploadDocument = (payload) => {
  return axios.post(`${baseURL}/client/project/upload_doc`, payload);
};

//Price List
export const priceList = () => {
  return axios.get(`${baseURL}/onboarding/client/price_list`);
};

// Start Next Iteration
export const startNextIteration = (payload) => {
  return axios.post(`${baseURL}/client/project/iteration/start/` + payload);
};

// Start Current Iteration
export const startCurrentteration = (payload) => {
  return axios.post(
    `${baseURL}/client/project/iteration/current/start/` + payload
  );
};

// Delete Client Upload File
export const deleteClientFile = (payload) => {
  return axios.delete(`${baseURL}/client/project/document/` + payload);
};

// Delete Client Upload File
export const deleteDevFile = (payload) => {
  return axios.delete(`${baseURL}/dev/project/document/` + payload);
};

//Client Mark A Sprint As Completed
export const markSprintCompleted = (payload) => {
  return axios.post(
    `${baseURL}/client/project/sprint/mark_as_completed`,
    payload
  );
};

//Client Mark A Sprint As Completed
export const editDevImage = (payload) => {
  return axios.post(`${baseURL}/dev/settings/profile_picture`, payload, {
    timeout: 100000,
  });
};

//Help and Support
export const helpAndSupport = (payload) => {
  return axios.post(`${baseURL}/support/help`, payload);
};

//Dev Uplaod Portfolio
export const devPortfolio = (payload) => {
  return axios.post(`${baseURL}/dev/settings/portfolio`, payload);
};

//Edit Team Bio
export const editTeamBio = (payload) => {
  return axios.post(`${baseURL}/dev/settings/team/bio`, payload);
};

//Edit Team Profile
export const editTeamProfile = (payload) => {
  return axios.put(`${baseURL}/dev/settings/team/profile`, payload);
};

// Add New Team Member
export const addNewTeamMember = (payload) => {
  return axios.post(`${baseURL}/dev/settings/add_new_member`, payload);
};

// List of States and Countries
export const allStateCountries = () => {
  return axios.get(`${baseURL}/repository/states_countries`);
};
// Tools and Frameworks
export const fetchToolsAndFrameWorks = () => {
  return axios.get(`${baseURL}/repository/tools2`);
};
//Dev Add Phone Number
export const devAddPhone = (payload) => {
  return axios.post(`${baseURL}/dev/settings/phone_number`, payload);
};

//Dev Add Portfolio Link
export const devPortfolioLink = (payload) => {
  return axios.put(`${baseURL}/dev/settings/portfolio`, payload);
};

//Dev Add Github Account /dev/settings/portfolio_links
export const devAddPortfolioLinks = (payload) => {
  return axios.post(`${baseURL}/dev/settings/portfolio_links`, payload);
};

export const getVCFromSlug2 = (payload) => {
  let url = `${baseURL}/onboarding/client/get/vc/${payload.slug}`;
  console.log(`getVCFromSlug2 :: ${url}`);
  return axios.get(`${url}`);

  //return axios.get(`${baseURL}/onboarding/client/get/vc/${payload}`);
  //return axios.get(`${baseURL}/onboarding/get/user`, payload);
};

//Dev Add Github Account /dev/settings/portfolio_links
export const devAddHowYouHeardAboutUs = (payload) => {
  return axios.post(`${baseURL}/dev/settings/hear_about_us`, payload);
};

//Change client password
export const clientChangePassword = (payload) => {
  return axios.put(`${baseURL}/client/settings/password`, payload);
};

//Change client name
export const clientChangeName = (payload) => {
  return axios.put(`${baseURL}/client/settings/name`, payload);
};

//Change client password
export const devTransferTeamLead = (payload) => {
  return axios.put(`${baseURL}/dev/settings/transfer_team_lead`, payload);
};
//Change client email
export const clientChangeEmail = (payload) => {
  return axios.put(`${baseURL}/client/settings/email`, payload);
};

//Change client email
export const emitEvent = () => {
  return axios.get(`${baseURL}/client/project/test/ws`);
};

// Fetch Dev Withdrawals
export const devWithdrawals = () => {
  return axios.get(`${baseURL}/dev/payment/withdrawal`);
};

//Dev Edit Portfolio
export const editPortfolio = (portId, payload) => {
  return axios.post(`${baseURL}/dev/settings/portfolio/` + portId, payload);
};

// Get team for work Mode
export const getTeamsforWorkMode = (projectId, payload) => {
  // this.$developConsole(projectId, payload);
  return axios.post(
    `${baseURL}/onboarding/client/select_team2/${projectId}`,
    payload
  );
};

// List of Nigeria Banks
export const bankList = () => {
  return axios.get(`https://api.paystack.co/bank`);
};

//Verify Bank Account
export const confirmAccount = (payload) => {
  return axios.post(
    `https://api.ravepay.co/flwv3-pug/getpaidx/api/resolve_account`,
    payload
  );
};

//Save Dev Bank Details
export const devBankDetail = (payload) => {
  return axios.post(`${baseURL}/dev/settings/bank`, payload);
};

//Forgot Password
export const forgotPassword = (payload) => {
  return axios.get(`${baseURL}/auth/password/forgot`, payload);
};

//Delete Project
export const delProject = (id, payload) => {
  return axios.post(`${baseURL}/client/project/delete/` + id, payload);
};

//Edit Project
export const editProject = (id, payload) => {
  return axios.put(`${baseURL}/client/project/` + id, payload);
};

// Fetch Team Mini Project
export const teamMiniProject = () => {
  return axios.get(`${baseURL}/dev/onboarding/project`);
};

// Voting on Mini Project
export const voteMiniProject = (payload) => {
  return axios.post(`${baseURL}/dev/onboarding/project/vote`, payload);
};

// Build Mini Project /dev/onboarding/project/select/projectID

export const buildMiniProject = (payload) => {
  return axios.post(`${baseURL}/dev/onboarding/project/select/` + payload);
};

// Submit Mini Project repo

export const submitMiniProject = (payload) => {
  return axios.post(`${baseURL}/dev/onboarding/project/submit`, payload);
};

// Onboarding Portfolio And Client
export const uploadOnboardingPortfolio = (payload) => {
  return axios.post(`${baseURL}/dev/onboarding/portfolio`, payload);
};

// Submit Portfolio
export const submitPortfolio = () => {
  return axios.post(`${baseURL}/dev/onboarding/portfolio/submit`);
};

// Resend Dev Invitation
export const resendDevInvite = (userId) => {
  return axios.post(`${baseURL}/dev/onboarding/resend_invite/` + userId);
};

// Remove Team Member
export const removeTeamMate = (payload) => {
  return axios.post(`${baseURL}/dev/settings/remove_member`, payload);
};

// Dev Confirm Document
export const confirmDocument = (payload) => {
  return axios.post(`${baseURL}/dev/project/doc/acknowledge/` + payload);
};

// Uplaod National ID
export const uploadIDcard = (payload) => {
  return axios.post(`${baseURL}/dev/onboarding/identification`, payload);
};

///dev/onboarding/identification
export const getIDCards = () => {
  return axios.get(`${baseURL}/dev/onboarding/identification`);
};
export const getIDCard = async () => {
  try {
    const { data: res } = await axios.get(
      `${baseURL}/dev/onboarding/identification`
    );
    return res;
  } catch (error) {
    return error;
  }
};

// Work with team display team info
export const workWithTeam = (payload) => {
  return axios.get(`${baseURL}/onboarding/client/work_with_team/` + payload);
};

// List of plans and prices
export const planAndPrices = (payload) => {
  return axios.post(`${baseURL}/prices`, payload);
};

// Change WorkMode and Service Plan forClient
export const changeWorkModePlan = (id, payload) => {
  return axios.put(`${baseURL}/client/project/plan/` + id, payload);
};

// Accepting SLA for client
export const acceptSLA = (payload) => {
  return axios.post(`${baseURL}/client/project/sla`, payload);
};

export const countryTimezones = () => {
  return axios.get(`${baseURL}/repository/timezones`);
};

export const devWorkExperience = (payload) => {
  return axios.post(`${baseURL}/dev/settings/work_experience`, payload);
};
//  Dev Personal Data
export const devUploadKyc = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/kyc`, payload, {
    timeout: 100000,
  });
};

//  Dev KYC And Address
export const devPersonalData = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/address`, payload, {
    timeout: 100000,
  });
};
export const flutterwaveAutoCharge = (payload) => {
  return axios.post(`${baseURL}/client/project/payment/kickoff`, payload);
};

export const setProjectLocation = (payload) => {
  return axios.post(`${baseURL}/client/project/location`, payload);
};

export const stripePayment = (payload) => {
  return axios.post(`${baseURL}/client/project/pay_with_stripe`, payload);
};

export const movingProjectSprint = (payload) => {
  return axios.post(`${baseURL}/dev/project/move-sprint`, payload);
};

export const clientPaymentCards = () => {
  return axios.get(`${baseURL}/client/wallet/cards`);
};

export const saveRaveCard = (payload) => {
  return axios.post(`${baseURL}/client/wallet/card/flutterwave`, payload);
};

// Delete Client Credit Card
export const deleteClientCreditCard = (id) => {
  return axios.delete(`${baseURL}/client/wallet/card/` + id);
};

// Change Default Payment Card
export const changeDefaultPaymentCard = (payload) => {
  return axios.post(`${baseURL}/client/wallet/card/default`, payload);
};

// Client Invite Collaborator to product
export const inviteCollaborator = (payload) => {
  return axios.post(`${baseURL}/client/project/collaborator`, payload);
};
// Edit the role of a collaborator
export const editCollaboratorRole = (payload) => {
  return axios.put(`${baseURL}/client/project/collaborator/update`, payload);
};
// Delete A Project Collaborator
export const deleteCollaborator = (id) => {
  return axios.delete(`${baseURL}/client/settings/collaborator/${id}`);
};

//Set Design Sprint Date
export const schduleDesignSprintDate = (payload) => {
  return axios.post(`${baseURL}/dev/project/design-sprint/kickoff`, payload);
};

// Design Sprint Rating
export const designSprintRating = (payload) => {
  return axios.post(`${baseURL}/client/project/rate-design-sprint`, payload);
};

// Moving And Re-ordering Features within Iteration
export const moveProjectFeature = (payload) => {
  return axios.post(`${baseURL}/dev/project/move-feature`, payload);
};

// dev/schedule_meeting
export const schduleDevMeeting = (payload) => {
  return axios.post(`${baseURL}/dev/schedule_meeting`, payload);
};

export const clientSideBar = () => {
  return axios.get(`${baseURL}/client/sidebar`);
};

export const changeCleintEmail = (payload) => {
  return axios.put(`${baseURL}/client/settings/email`, payload);
};

export const productSectors = () => {
  return axios.get(`${baseURL}/guest/sectors`);
};

//Debit card auto debit
export const debitCardAutoCharge = (payload) => {
  return axios.post(`${baseURL}/client/project/recharge-customer`, payload);
};

export const clientBookTeam = (payload) => {
  return axios.post(`${baseURL}/charge-customer-priority-pay`, payload);
};
// Get User Linkedin Profile Details
export const getLinkedinProfile = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/get-linkedin-profile`, payload);
};

//Set Design Sprint Date
export const schduleDesignSprintInterviewDate = (payload) => {
  return axios.post(`${baseURL}/dev/project/design_sprint`, payload);
};

export const getTeamProject = (projectId) => {
  return axios.get(`${baseURL}/client/project/team/${projectId}`);
};

// Client Wallet/Payment Api
export const clientWalletApi = (projectId) => {
  return axios.post(`${baseURL}/client/project/payments/${projectId}`);
};

// Complete project details with team-members
export const completeProjectDetails = (id) => {
  return axios.get(`${baseURL}/client/project/2/` + id);
};

// Complete project details with team-members
export const projectDocuments = (id) => {
  return axios.get(`${baseURL}/client/project/docs/` + id);
};

//

export const getDevWorkExperience = () => {
  return axios.get(`${baseURL}/dev/settings/work_experience`);
};

export const getDevEducation = () => {
  return axios.get(`${baseURL}/dev/get-education`);
};

//remove work experience
export const removeWorkExperienceAPI = (id) => {
  return axios.get(`${baseURL}/dev/settings/work_experience/remove/` + id);
};
// https://apiservices.grupa.io/beta/api/3rdparty/google-calendar2
// https://grupa-live.herokuapp.com
export const googleCalendarEvents = () => {
  return axios.post(`${baseURL}/3rdparty/google-calendar2`);
};

//Dev schedule video interview with Grupa
export const scheduleMeeting = (payload) => {
  return axios.post(`${baseURL}/dev/onboarding/meeting-booked`, payload);
};

// Client Add Project Overview
export const AddProjectOverView = (id, payload) => {
  return axios.post(`${baseURL}/onboarding/client/overview/` + id, payload);
};

// Schedule first meeting
export const teamPreviewMeeting = (payload) => {
  return axios.post(`${baseURL}/client/project/schedule/preview`, payload);
};

// Create 5 days design sprint
export const createDesignSprint = (payload) => {
  return axios.post(`${baseURL}/projects/design-sprint/create`, payload);
};

//  Select Developers for a project
export const selectDevsProject = (payload) => {
  return axios.post(`${baseURL}/temp/dev/team-lead/create-developers`, payload);
};

// Team Sign NDA for a Project
export const teamSignNDA = (project_id, address) => {
  return axios.get(
    `${baseURL}/projects/design-sprint/accept-nda/${project_id}/${address}`
  );
};

// Team To Rate First Meeting
export const rateFirstMeeting = (payload) => {
  return axios.post(
    `${baseURL}/projects/design-sprint/create/dev-rate-meeting`,
    payload
  );
};

// Team Accepting First Meeting Invite
export const teamAcceptMeetingInvite = (project_id) => {
  return axios.get(
    `${baseURL}/projects/design-sprint/team-accepted/${project_id}`
  );
};

// Confirm work with team
export const confirmWorkWithTeam = (payload) => {
  return axios.post(
    `${baseURL}/client/project/confirm-work-with-team`,
    payload
  );
};

// Team Accepting First Meeting Invite
export const fetchProjectDesignSprint = (project_id) => {
  return axios.get(`${baseURL}/projects/design-sprint/${project_id}`);
};

//
export const startRoadMap = (project_id) => {
  return axios.get(
    `${baseURL}/projects/design-sprint/update/road-map/${project_id}`
  );
};

export const getProjectActivityLog = (id) => {
  return axios.get(`${baseURL}/dev/project/get-activities/` + id);
};

// export const getProjectActivityLog = (id) => {
//   return axios.get(`${baseURL}/dev/project/get-activities/` + id);
// };

export const getSprintBurn = (id) => {
  return axios.get(
    `${baseURL}/client/project/sprint/get-sprint-burnup-chart/` + id
  );
};

export const teamVelocity = (id) => {
  return axios.get(`${baseURL}/client/project/sprint/get-velocity-chart/` + id);
};
// devStartSprintOne

// get specializations
export const getSepcializations = () => {
  return axios.get(`${baseURL}/repositories/specializations`);
};

//Select team for project
export const getTeamsforProject = (projectId, payload) => {
  return axios.post(
    `${baseURL}/onboarding/client/find_best_team/${projectId}`,
    payload
  );
};

//Select team for project
export const getEnginnerforProject = (projectId, payload) => {
  return axios.post(
    `${baseURL}/onboarding/client/find_best_project_engineer_by_role/${projectId}`,
    payload
  );
};

//Grupa Prices
export const grupaPrices = (payload) => {
  return axios.post(`${baseURL}/prices`, payload);
};

// Team-onboarding, get a team info
export const getMyTeamInfo = () => {
  return axios.get(`${baseURL}/repository/my_team`);
};

// Team-onboarding, Team_lead create team
export const teamLeadCreateTeam = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/create_team`, payload);
};

// Team-onboarding, Team_lead invite team members
export const inviteTeamMembers = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/invite_team`, payload);
};

// Dev onboarding add tools and language/frameworks
export const addToolsAndFramework = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/complete_profile`, payload);
};

// Dev roles
export const devTeamRoles = () => {
  return axios.get(`${baseURL}/dev/roles`);
};

export const deleteWorkExperience = (id) => {
  return axios.delete(`${baseURL}/dev/work-experience/` + id);
};

// Get customer satisfaction
export const customerSatisfaction = (id) => {
  return axios.get(
    `${baseURL}/client/project/sprint/get-customer-satisfaction/` + id
  );
};

// Get User Referral
export const getUserReferral = () => {
  return axios.get(`${baseURL}/referral/get-referrals`);
};

// Send Referral Email
export const sendReferralEmail = (type, payload) => {
  return axios.post(`${baseURL}/referral/send-invite/${type}`, payload);
};

// Invite team FAANG
export const inviteTeamMates = (payload) => {
  return axios.post(`${baseURL}/onboarding/dev/invite_team_2`, payload);
};

// Get List of Startups
export const getAllStartUps = () => {
  return axios.get(`${baseURL}/onboarding/get/startups`).then((res) => {
    if (res.data.status == 1) {
      const startups = res.data.data;
      // hack to workaround startups api not returning project data. limit to 25 to not stress server
      return Promise.all(
        startups
          .reverse()
          .slice(0, 25)
          .map((startup) => getStartUp(startup.id))
      )
        .then((detailedStartups) => {
          detailedStartups.forEach((detailedStartupResult, index) => {
            if (detailedStartupResult.data.status == 200) {
              startups[index].projects =
                detailedStartupResult.data.data.projects;
            }
          });
          return res;
        })
        .catch((err) => {
          console.error(err);
          return res;
        });
    }

    return res;
  });
};

export const getStartUp = (id) => {
  return axios.get(`${baseURL}/onboarding/startup/${id}`);
};

// The activate user account
export const deactivateAccount = () => {
  return axios.get(`${baseURL}/dev/settings/user/deactivate`);
};

// Invite team FAANG
export const UpdateTimeZone = (payload) => {
  return axios.post(`${baseURL}/user/user/update_timezone`, payload);
};

export const linkedinScrapper = (payload) => {
  return axios.post(
    `https://grupa-linkedin.herokuapp.com/api/profile`,
    payload
  );
};
