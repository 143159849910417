const defaultState = {
  teamInfo: null,
  teamProjects: [],
  selectedProject: {},
  selectedProjectDoc: [],
  featureComments: [],
  projectActivity: [],
  newProduct: {},
  specializations: [],
  team: [],
  singleProjectTeam: [],
  teamActivation: 0,
  currentStage: 0,
  startupList: [],
  currentStartupIndex: 0,
};

const mutations = {
  setTeamInfo(state, payload) {
    state.teamInfo = payload;
  },
  setTeamMembers(state, payload) {
    state.teamInfo.team.members = payload.members;
  },
  setSpecialization(state, payload) {
    state.specializations = payload.specializations;
  },
  setTeamProjects(state, payload) {
    state.teamProjects = payload;
  },
  updateUserActivation(state, payload) {
    state.teamInfo.user = payload;
  },
  updateTeamActivationLevel(state, payload) {
    if (payload.members) {
      state.teamInfo.team = payload;
    } else {
      state.teamInfo.team.activation_level = payload.activation_level;
    }
  },
  updateTeamData(state, payload) {
    state.teamInfo.team = payload;
  },
  updateSelectedProject(state, payload) {
    state.selectedProject = payload;
  },
  updateSelectedProjectDoc(state, payload) {
    state.selectedProjectDoc = payload;
    state.selectedProject = payload.project;
    state.singleProjectTeam = payload.members;
  },
  createProjectTask(state, payload) {
    state.selectedProjectDoc.tasks.push(payload);
  },
  updateProjectTask(state, payload) {
    state.selectedProjectDoc.tasks = payload;
  },
  updateProjectFeatures(state, payload) {
    state.selectedProjectDoc.features = payload;
  },
  updateProjectIterations(state, payload) {
    state.selectedProjectDoc.iterations = payload;
  },
  setFeatureComments(state, payload) {
    state.featureComments = payload;
  },
  updateFeatureComments(state, payload) {
    state.featureComments.unshift(payload);
  },
  setProjectActivity(state, payload) {
    state.projectActivity = payload;
  },
  setNewProject(state, payload) {
    state.newProduct = payload;
  },
  addPortfolio(state, payload) {
    state.teamInfo.portfolio.push(payload);
  },
  updateTeamPortfolio(state, payload) {
    state.teamInfo.portfolio = payload;
  },
  updateDeveloperInfo(state, payload) {
    state.teamInfo.user = payload;
  },
  updateTeamActivation(state, payload) {
    state.teamActivation = payload;
  },
  updateCurrentUser(state, payload) {
    state.teamInfo.user = payload;
  },
  updateCurrentStage(state, payload) {
    state.currentStage = payload;
  },
  setStartupIndex(state, payload) {
    state.currentStartupIndex = payload;
  },
  setStartupList(state, payload) {
    state.startupList = payload;
  },
};

const actions = {
  setTeamDetails({ commit }, payload) {
    commit("setTeamInfo", payload.data);
    if (payload.data.projects) {
      commit("setTeamProjects", payload.data.projects);
    }
  },
  updateTeamActivationLevel({ commit }, payload) {
    commit("updateTeamActivationLevel", payload);
  },

  updateTeamData({ commit }, payload) {
    commit("updateTeamData", payload);
  },
  updateUserActivation({ commit }, payload) {
    commit("updateUserActivation", payload);
  },
  updateSelectedProject({ commit }, payload) {
    commit("updateSelectedProject", payload);
  },
  updateSelectedProjectDoc({ commit }, payload) {
    commit("updateSelectedProjectDoc", payload);
  },
  createProjectTask({ commit }, payload) {
    commit("createProjectTask", payload);
  },
  updateProjectTask({ commit }, payload) {
    commit("updateProjectTask", payload);
  },
  updateProjectFeatures({ commit }, payload) {
    commit("updateProjectFeatures", payload);
  },
  updateProjectIterations({ commit }, payload) {
    commit("updateProjectIterations", payload);
  },
  setFeatureComments({ commit }, payload) {
    commit("setFeatureComments", payload);
  },
  updateFeatureComments({ commit }, payload) {
    commit("updateFeatureComments", payload);
  },
  setProjectActivity({ commit }, payload) {
    commit("setProjectActivity", payload);
  },
  setNewProject({ commit }, payload) {
    commit("setNewProject", payload);
  },
  updateSpecializations({ commit }, payload) {
    commit("setSpecialization", payload);
  },
  updateTeamMembers({ commit }, payload) {
    commit("setTeamMembers", payload);
  },
  addPortfolio({ commit }, payload) {
    commit("addPortfolio", payload);
  },
  updateTeamPortfolio({ commit }, payload) {
    commit("updateTeamPortfolio", payload);
  },
  updateDeveloperInfo({ commit }, payload) {
    commit("updateDeveloperInfo", payload);
  },
  updateTeamActivation({ commit }, payload) {
    commit("updateTeamActivation", payload);
  },
  updateCurrentUser({ commit }, payload) {
    commit("updateCurrentUser", payload);
  },
  updateCurrentStage({ commit }, payload) {
    commit("updateCurrentStage", payload);
  },
  setStartupList({ commit }, payload) {
    commit("setStartupList", payload);
  },
  setStartupIndex({ commit }, payload) {
    commit("setStartupIndex", payload);
  },
};

const getters = {
  // messages: state => state.messages,
};

const namespaced = true;
export default {
  namespaced,
  state: defaultState,
  getters,
  actions,
  mutations,
};
