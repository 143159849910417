import { validateClient, validateDev } from "@/components/authGuide";
// Layout Routes
const devLayout = () => import(`@/layout/DevLayout`);
const devProjectLayout = () => import(`@/layout/DevProjectLayout`);

const clientLayout = () => import(`@/layout/ClientLayout`);
const PreProjectLayout = () => import(`@/layout/PreProjectLayout`);
// Team Dashboard Routes
const teamWorkSpace = () => import(`@/views/DevDashboard/overview`);
const projectWorkSpace = () =>
  import(`@/views/DevDashboard/products/workspace`);
const devRoadMap = () => import(`@/views/DevDashboard/products/roadmap`);
const devWallet = () => import(`@/views/DevDashboard/wallet`);
const devProfile = () => import(`@/views/DevDashboard/profile`);
const teamManagement = () => import(`@/views/DevDashboard/team`);
const devProjects = () => import(`@/views/DevDashboard/products`);
const clientProfile = () => import(`@/views/ClientDashboard/profile`);
const scheduleProjectMeeting = () =>
  import(`@/views/DevDashboard/products/scheduleMeeting`);
const productTeam = () => import(`@/views/DevDashboard/products/teamMgt`);
const teamDocuments = () => import(`@/views/DevDashboard/products/documents`);
const teamAnalytics = () => import(`@/views/DevDashboard/products/analytics`);

// Client Dashboard Routes
const clientWorkspace = () => import(`@/views/ClientDashboard/workspace`);
const clientRoadMap = () => import(`@/views/ClientDashboard/roadmap`);
const viewTeam = () =>
  import(`@/views/ClientDashboard/workspace/prekickoff/view-team`);
const clientPayments = () => import(`@/views/ClientDashboard/payments`);
const productCollaborators = () =>
  import(`@/views/ClientDashboard/collaborators`);
const clientDocument = () => import(`@/views/ClientDashboard/documents`);
const productAnalytics = () => import(`@/views/ClientDashboard/analytics`);
const ReferralSystem = () => import(`@/views/referral`);
const ViewStartup = () =>
  import(
    `@/views/DevDashboard/overview/vetting/startUps/partials/viewStartup.vue`
  );
const ClientDashboard = () => import(`@/views/ClientDashboard/overview`);
const JoinViaSocialLink = () => import(`@/views/JoinViaSocialLink`);

const PageNotFound = () => import(`@/views/404NotFound`);

const dashboardRoutes = [
  {
    path: "/dashboard/dev",
    component: devLayout,
    props: true,
    children: [
      {
        path: "",
        name: "dev_dashboard",
        component: teamWorkSpace,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "projects",
        name: "dev_projects",
        component: devProjects,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "dev-team",
        name: "dev_team",
        // component: dev_dashboard,
        props: true,
      },
      {
        path: "wallet",
        name: "dev_wallet",
        component: devWallet,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "team",
        name: "team",
        component: teamManagement,
        meta: { auth: validateDev() },
        props: true,
      },
      {
        path: "dev-profile",
        name: "dev_profile",
        component: devProfile,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "referral",
        name: "dev-referral",
        component: () => import(`@/views/referral`),
        props: true,
        meta: { auth: validateDev(), reload: true },
      },
    ],
  },
  //
  /* Start of Routes for Viewing a single Project (Dev) */
  {
    path: "/dashboard/dev/project/:id",
    component: devProjectLayout,
    props: true,
    children: [
      {
        path: "workspace",
        name: "dev_workspace",
        component: projectWorkSpace,
        props: true,
        meta: { auth: validateDev() },
      },

      {
        path: "backlog",
        name: "dev_backlog",
        component: projectWorkSpace,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "roadmap",
        name: "roadmap",
        component: devRoadMap,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "team-mgt",
        name: "team_mgt",
        component: productTeam,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "team-doc",
        name: "team_doc",
        component: teamDocuments,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "team-analytics",
        name: "team_analytics",
        component: teamAnalytics,
        props: true,
        meta: { auth: validateDev() },
      },
      {
        path: "schedule-meetings",
        name: "schedule_meetings",
        component: scheduleProjectMeeting,
        props: true,
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard_client_project",
    component: PreProjectLayout,
    props: true,
    children: [
      {
        path: "overview",
        name: "client_overview",
        component: ClientDashboard,
        props: true,
        meta: { auth: validateClient(), reload: true },
      },
    ],
  },
  {
    path: "/dashboard/project/:id",
    name: "project",
    component: clientLayout,
    props: true,
    children: [
      {
        path: "workspace",
        name: "project_workspace",
        component: clientWorkspace,
        props: true,
        meta: { auth: validateClient() },
      },
      {
        path: "roadmap",
        name: "client_roadmap",
        component: clientRoadMap,
        props: true,
        meta: { auth: validateClient() },
      },
      {
        path: "product-team",
        name: "product_team",
        // component: team,
        props: true,
      },
      {
        path: "payments",
        name: "client_payments",
        component: clientPayments,
        props: true,
      },
      {
        path: "collaborators",
        name: "product_collaborators",
        component: productCollaborators,
        props: true,
      },
      {
        path: "documents",
        name: "document_tab",
        component: clientDocument,
        props: true,
      },
      {
        path: "help_and_feedback",
        name: "client_help_and_feedback",
        // component: help_and_feedback,
        props: true,
      },
      {
        path: "client-view-team/:team_name",
        name: "client_view_team",
        component: viewTeam,
        props: true,
        meta: { auth: validateClient() },
      },
      {
        path: "analytics",
        name: "project_analytics",
        component: productAnalytics,
        props: true,
        meta: { auth: validateClient() },
      },
      {
        path: "profile",
        name: "client_profile",
        component: clientProfile,
        props: true,
        meta: { auth: validateClient() },
      },
      {
        path: "referral",
        name: "referral",
        component: ReferralSystem,
        props: true,
        meta: { auth: validateClient(), reload: true },
      },
    ],
  },

  {
    path: "/view-startup/:id",
    component: ViewStartup,
    props: true,
    name: "view_startup",
  },

  {
    path: "/join/:type/:social",
    component: JoinViaSocialLink,
    props: true,
    name: "join_social",
  },

  { path: "*", component: PageNotFound },
];

export default dashboardRoutes;
