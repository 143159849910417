<template>
  <div id="app">
    <v-app light v-cloak>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
import Axios from "axios";
import countryFinder from "country-finder";
import { mapActions, mapState } from "vuex";
import { getOS } from "./utils/getUserInfo";
export default {
  name: "app",
  components: {},
  data: () => ({
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
  }),
  created() {
    this.getUserLocation();
  },
  methods: {
    ...mapActions("userManagement", [
      "setAutheticatedUser",
      "setUserLocation",
      "setUserIpAddress",
    ]),
    ...mapActions("modalMgmt", ["toogleViewProfile"]),
    async getUserLocation() {
      try {
        const res = await Axios.get("https://httpbin.org/ip");

        // console.log("getUserLocation :: ", res);
        const origin = res.data.origin.split(",");
        let ip = origin[0];
        this.setUserIpAddress(ip);
        // if (this.userLocation == null) {
        // await Axios.get(`https://ipinfo.io/${ip}?token=d8e19ce98ae1da`)
        const userTimeZone = await this.getTimeZone(ip);
        const resp = await Axios.post(
          `${process.env.VUE_APP_BACKEND_API}/open/location`,
          //`https://grupa-api-pre-live.herokuapp.com/api/open/location`,
          {
            ip,
          }
        )
          .then((resp) => {
           // console.log("resp :::: ",resp)
            let location = resp.data.data.location;
            let countryDetails = countryFinder.byCountryCode(location.country);

            if (location.hasOwnProperty("continent_name")) {
              location.continent_name = countryDetails.continent;
            }

            //location.country_name = countryDetails.name;
            if (location.hasOwnProperty("country_name")) {
              location.country_name = countryDetails.country_name;
            }

            //location.country_code = location.country;
            if (location.hasOwnProperty("country_code")) {
              location.country_code = countryDetails.country_code;
            }

            // location.loginDate = new Date();
            if (location.hasOwnProperty("loginDate")) {
              location.loginDate = new Date();
            }

            //location.os = getOS();
            if (location.hasOwnProperty("os")) {
              location.os = getOS();
            }

            // location.timezone = userTimeZone;
            if (location.hasOwnProperty("timezone")) {
              location.timezone = userTimeZone;
            }

            this.setUserLocation(location);
          })
          .catch((error) => {
            console.log("userTimeZone :: ", error);
          });
      } catch (error) {
        this.$developConsole(error, "ip");
      }
      // }
    },
    async getTimeZone(ipAddress) {
      const response = await Axios.get(
        `https://ipapi.co/${ipAddress}/timezone`
      );
      return response.data;
    },
  },
  computed: {
    ...mapState("userManagement", ["userLocation"]),
  },
};
</script>

<style>
@import "../public/css/bootstrap.min.css";
@import "../public/css/style.css";
@import "../public/css/onboarding.css";
@import "../public/css/main.css";
@import "../public/css/buttons.css";
@import "../public/css/layout.css";
@import "../public/css/dashboard.css";
@import "../public/css/roadmapping.css";
@import "../public/css/personalizedDashboard.css";
/*
@import "../public/css/style-semantic.css"; */
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background-color: #fff;
  /* margin-top: 60px; */
  /* max-width: 1440px;
  margin: 0 auto; */
}
</style>