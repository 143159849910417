import Vue from "vue";
import Vuex from "vuex";
import modalManagement from "./modules/modalMgmt/modalMgmt";
import clientOnboardingStore from "./modules/clientOnboarding/clientRegistration";
import clientPaymentsStore from "./modules/paymentModule/clientPayments";
import clientDashboardStore from "./modules/clientDashboard/clientProjects";
import userManagementStore from "./modules/userModule/userManagement";
import selectTeamForProject from "./modules/selectTeam";

import { developerOnboardingStore } from "./modules/devOnboarding";
import devDashboardStore from "./modules/devDashboard";
import miniProject from "./modules/teamMiniproject";
import alertMsg from "./modules/messageMgmt";
import createPersistedState from "vuex-persistedstate";
import devPrekickoff from "./modules/devPrekickoff";
import clientPrekickoff from "./modules/clientPrekickoff";
import popupManagement from "./modules/popupMgmt";
import loaderManagement from "./modules/loaderMgmt";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    devOnboarding: developerOnboardingStore,
    alertMsg,
    devDashboard: devDashboardStore,
    miniProject: miniProject,
    userManagement: userManagementStore,
    clientDashboard: clientDashboardStore,
    selectTeamForProject: selectTeamForProject,
    devPrekickoff: devPrekickoff,
    modalMgmt: modalManagement,
    clientPrekickoff,
    popupManagement: popupManagement,
    loaderManagement: loaderManagement,
    // clientPayments: clientPaymentsStore,
    // clientOnboarding: clientOnboardingStore,
  },
  plugins: [createPersistedState()],
  strict: debug,
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetVuexState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
